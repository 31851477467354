/* eslint-disable guard-for-in */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import { createSlice } from '@reduxjs/toolkit'
import { IAudioManagement } from '../../models/audioManagement/audioManagement'
import { setLiveSession, setMeta, updateGetter } from './actions'

export const initialAudioManagement: IAudioManagement = {
  liveSession: {
    session_id: '',
    avatar_display_name: '',
    avatar_id: '',
    is_recording: false,
    is_started: false,
    available_devices: {
      input_devices: null,
      output_devices: null,
    },
  },
  meta: {},
  pauseGetter: false,
}

export const audioManagementSlice = createSlice({
  name: 'authorization',
  initialState: initialAudioManagement,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(setLiveSession.match, (state, { payload }) => {
      state.liveSession = payload
    })
    builder.addMatcher(updateGetter.match, (state, { payload }) => {
      state.pauseGetter = payload
    })
    builder.addMatcher(setMeta.match, (state, { payload }) => {
      state.meta = payload
    })
  },
})

export const audioManagementReducer = audioManagementSlice.reducer
