/* eslint-disable guard-for-in */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import { createSlice } from '@reduxjs/toolkit'
import {
  setEULAAccept,
  setIsAcceptingEULA,
  setSelectedParameters,
  setUserData,
} from './actions'
import { IUser } from '../../models/user/user'
import { v4 as uuidv4 } from 'uuid'

const tabFingerprint = uuidv4()
let browserFingerprint = localStorage.getItem('browserFingerprint')

if (!browserFingerprint) {
  browserFingerprint = uuidv4()
  localStorage.setItem('browserFingerprint', browserFingerprint)
}

export const initialUser: IUser = {
  selected_parameters: [],
  tenant_display_name: '',
  can_be_recorded: false,
  can_change_parameter: false,
  is_eula_accepted: false,
  custom_inference_url: '',
  can_show_quality_survey: false,
  eula_url: '',
  is_supervisor: false,
  meaning_user_id: '',
  meaning_user_external_id: '',
  user: '',
  isSSOAuthenticated: false,
  email: '',
  data_center: '',
  is_annotator: false,
  features: [],
  last_name: '',
  first_name: '',
  s3_models: [],
  tenant_id: '',
  logout_url: '',
  potential_issues: [],
  should_show_device_test: false,
  is_first_login: true,
  isLoadingEula: false,
  tabFingerprint: tabFingerprint,
  browserFingerprint: browserFingerprint,
}

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUser,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(setUserData.match, (state, { payload }) => {
      return {
        ...state,
        ...payload,
        s3_models:
          payload?.s3_models.map((model) => ({
            ...model,
            voices: model.voices.map((voicesElements) => ({
              ...voicesElements,
              model_id: model.model_id,
              latency: model.latency,
            })),
          })) || [],
      }
    }),
      builder.addMatcher(setIsAcceptingEULA.match, (state, { payload }) => {
        state.isLoadingEula = payload
      }),
      builder.addMatcher(setSelectedParameters.match, (state, { payload }) => {
        state.selected_parameters = payload
      }),
      builder.addMatcher(setEULAAccept.match, (state, { payload }) => {
        state.is_eula_accepted = payload
      })
  },
})

export const userReducer = userSlice.reducer
