import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  Input,
  Button,
  IconButton,
  Flex,
  Text,
  Box,
  InputGroup,
  InputRightElement,
  Spinner,
  Checkbox,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  InputLeftElement,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Column } from 'react-table'
import { FiFilter } from 'react-icons/fi'
import { Select } from 'chakra-react-select'
import { AddIcon, MinusIcon, SearchIcon } from '@chakra-ui/icons'
import { uniqBy } from 'ramda'
import ReactStars from 'react-stars'

export const Filters = ({
  column,
  usedFilters,
}: {
  usedFilters: { key: string; value: string }[]
  column: Column
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef: any = React.useRef()
  const filtersInstance = (column as any)?.columns?.filter(
    ({ filterType, canFilter }: any) =>
      filterType !== 'datepicker' && canFilter,
  )

  return (
    <>
      <IconButton
        data-qa__id="table_filters_button"
        ref={btnRef}
        variant="outline"
        px="7"
        onClick={() => {
          onOpen()
        }}
        aria-label={'Filters'}
        icon={
          <Box position="relative">
            <FiFilter />
            {usedFilters && usedFilters.length > 0 && (
              <Flex
                justifyContent="center"
                alignItems="center"
                w="3"
                right="-6px"
                bottom="-6px"
                h="3"
                position="absolute"
                borderRadius="50%"
                background="var(--chakra-colors-brand-500)"
                color="white"
                fontSize="8px"
                pt="1px"
                data-qa__id="table_filters_count"
              >
                {usedFilters.length}
              </Flex>
            )}
          </Box>
        }
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton data-qa__id="table_filters_close_button" />
          <DrawerHeader>Filters</DrawerHeader>
          <DrawerBody
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'var(--chakra-colors-brand-500)',
                borderRadius: '24px',
              },
            }}
            data-qa__id="table_filters_body"
          >
            <Accordion
              allowMultiple
              defaultIndex={filtersInstance
                .map((el: any, index: any) =>
                  el.filterValue ? index : undefined,
                )
                .filter((el: any) => el !== undefined)}
            >
              {filtersInstance.map(
                (
                  {
                    id,
                    Header,
                    setFilter,
                    filterHeader,
                    filterKey,
                    filterValue,
                    filterType,
                    onSearchField,
                    selectOptions,
                    placeholder,
                    isLoadingSearchValues,
                    searchResults,
                    isSearchCheckbox,
                  }: any,
                  index: any,
                ) => {
                  const uniqueCheckbox = uniqBy(
                    ({ id }) => id,
                    [...(searchResults || [])],
                  )

                  const filters = usedFilters.filter(
                    (usedFilter) => usedFilter.key === filterKey,
                  )
                  const [searchField, setSearchField] = useState('')
                  useEffect(() => {
                    setSearchField('')
                  }, [])
                  return (
                    ((filterType === 'select' && selectOptions.length > 0) ||
                      filterType !== 'select') && (
                      <AccordionItem
                        key={`filters_instance_${Header}_${index}_${id}`}
                        data-qa__id="table_filters_category"
                      >
                        {({ isExpanded }) => (
                          <>
                            <h2>
                              <AccordionButton>
                                <Box flex="1" textAlign="left">
                                  <Text fontWeight="semibold">
                                    {filterHeader ? filterHeader : Header}
                                  </Text>
                                </Box>
                                {isExpanded ? (
                                  <MinusIcon
                                    fontSize="12px"
                                    data-qa__id="table_filters_category_close"
                                  />
                                ) : (
                                  <AddIcon
                                    fontSize="12px"
                                    data-qa__id="table_filters_category_open"
                                  />
                                )}
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              {(filterType === 'checkbox' ||
                                filterType === 'scores') && (
                                <Flex flexDirection="column">
                                  {uniqueCheckbox.length > 0 &&
                                    isSearchCheckbox && (
                                      <InputGroup>
                                        <InputLeftElement pointerEvents="auto">
                                          <SearchIcon
                                            cursor="auto"
                                            color="#a0aec0"
                                            boxSize="5"
                                          />
                                        </InputLeftElement>
                                        <Input
                                          onChange={(event) => {
                                            if (onSearchField) {
                                              onSearchField(event.target.value)
                                            }
                                            setSearchField(event.target.value)
                                          }}
                                          value={searchField}
                                          bg="white"
                                          fontSize="sm"
                                          placeholder={
                                            placeholder
                                              ? placeholder
                                              : `${Header} search`
                                          }
                                        />
                                        {isLoadingSearchValues && (
                                          <InputRightElement pointerEvents="none">
                                            <Spinner />
                                          </InputRightElement>
                                        )}
                                      </InputGroup>
                                    )}
                                  {uniqueCheckbox.length > 0 ? (
                                    uniqueCheckbox
                                      .filter(({ label }) => {
                                        return searchField === ''
                                          ? true
                                          : label
                                              .toLowerCase()
                                              .includes(
                                                searchField.toLowerCase(),
                                              )
                                      })
                                      .map((filterItem, index) => {
                                        const isChecked = filters.some(
                                          (filter) =>
                                            filter.value === filterItem.id,
                                        )

                                        return (
                                          <Checkbox
                                            mt="2"
                                            key={`checkbox_${filterItem.id}`}
                                            isChecked={isChecked}
                                            onChange={(e) => {
                                              if (!isChecked) {
                                                setFilter([
                                                  ...filters,
                                                  {
                                                    value: filterItem.id,
                                                    key: filterKey,
                                                  },
                                                ])

                                                return
                                              }

                                              setFilter(
                                                filters.filter(
                                                  (filter) =>
                                                    filter.value !==
                                                    filterItem.id,
                                                ),
                                              )
                                            }}
                                            data-qa__id="table_filters_item"
                                          >
                                            <Flex
                                              fontSize="16px"
                                              fontWeight="400"
                                              alignItems="center"
                                            >
                                              {filterType === 'checkbox' ? (
                                                filterItem.label
                                              ) : (
                                                <ReactStars
                                                  value={filterItem.label}
                                                  half={false}
                                                  count={5}
                                                  edit={false}
                                                  size={12}
                                                  color2="#1b91d6"
                                                />
                                              )}{' '}
                                              ({filterItem.count})
                                            </Flex>
                                          </Checkbox>
                                        )
                                      })
                                  ) : (
                                    <Text pl="2">
                                      {!isSearchCheckbox && 'No results'}
                                    </Text>
                                  )}
                                </Flex>
                              )}
                              {filterType === 'text' && (
                                <Input
                                  onChange={(e) => {
                                    setFilter(e.target.value || undefined)
                                  }}
                                  bg="white"
                                  value={filterValue || ''}
                                  fontSize="sm"
                                  placeholder={Header}
                                />
                              )}
                              {filterType === 'select' && (
                                <Select
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPortalTarget={document.body}
                                  closeMenuOnSelect={false}
                                  isClearable={true}
                                  maxMenuHeight={160}
                                  classNamePrefix="filter_select"
                                  isMulti={true}
                                  value={filterValue || ''}
                                  onChange={(value) =>
                                    setFilter(value || undefined)
                                  }
                                  options={selectOptions}
                                />
                              )}
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    )
                  )
                },
              )}
            </Accordion>
          </DrawerBody>

          <DrawerFooter>
            <Button
              variant="outline"
              mr={3}
              onClick={() => {
                onClose()
              }}
              data-qa__id="table_filters_footer_close_button"
            >
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
