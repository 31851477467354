import { ajax } from 'rxjs/ajax'
import { apiVersion } from '..'

export const acceptEulaService = (userId: string) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/user/accept_eula/`,
    method: 'PUT',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      meaning_user_id: userId,
    },
  })
}

export const updateParameter = (
  body: {
    model_id: string
    avatar_identifier: string
    key: string
  }[],
) => {
  return ajax({
    url: `${process.env.REACT_APP_API_URL}/api/${apiVersion}/user/parameters/`,
    method: 'POST',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
}
