import { format } from 'date-fns'
import { datesFormats } from '../../utils/datesFormats'
import { Flex, Text, Grid, Box } from '@chakra-ui/react'
import React from 'react'
import { IAudios } from './audio.utils'
import { IAnnotationsElement } from '../../models/annotations/annotations'
import { sound, SoundLibrary } from '@pixi/sound'
import { IPlayer } from './sessionAnnotationPanels/sessionAnnotationPanel'
import { ISessionDetails } from '../../models/session/session'

export const sessionMetadata = (session: any, sessionMetadata: any) => {
  const userData = [
    {
      name: 'User',
      data: `${session.user?.first_name} ${session.user?.last_name} (${session.user?.username})`,
      extraData: '',
    },
    {
      name: 'Created at',
      data:
        'created_at' in session
          ? format(
              // @ts-ignore
              new Date(session.created_at),
              datesFormats.dateWithHour,
            )
          : '',
      extraData: '',
    },
    {
      name: 'Avatar',
      data: `${session.conversions?.[0]?.name || 'None'}`,
      extraData: `${session.conversions?.[0]?.avatar.description || 'None'}`,
      valueIcon: `fi fi-${session.conversions?.[0]?.avatar.accent || 'us'}`,
    },
  ]

  return (
    <Grid
      justifyContent="flex-end"
      w="550px"
      gap="1"
      gridTemplateRows="1fr 1fr"
    >
      {userData.map(({ name, data, extraData, valueIcon }, index) => (
        <React.Fragment key={`${name}_${data}_${index}`}>
          <Flex
            gap="2"
            alignItems="center"
            data-qa__id="session_info"
            justifyContent="flex-end"
          >
            <Text
              fontSize="sm"
              fontWeight="semibold"
              data-qa__id="session_info_title"
            >
              {name}:
            </Text>
            <Box fontSize="xs" data-qa__id="session_info_data">
              {valueIcon ? (
                <Box
                  as="span"
                  borderRadius="2px"
                  mr="5px"
                  className={valueIcon}
                ></Box>
              ) : null}
              {data}
              {extraData && (
                <Text
                  display="inline"
                  color={
                    extraData === 'soften'
                      ? 'rgb(22,175,204)'
                      : extraData === 'shift'
                      ? 'rgb(132,84,176)'
                      : 'rgb(254,145,9)'
                  }
                >
                  &nbsp;({extraData})
                </Text>
              )}
            </Box>
          </Flex>
        </React.Fragment>
      ))}
    </Grid>
  )
}

export const playAnnotation = ({
  audio,
  annotation,
  duration,
  sound,
  setPlayer,
  player,
  data,
  renderProgress,
}: {
  audio?: IAudios | null
  duration: number
  annotation: IAnnotationsElement
  sound: SoundLibrary
  setPlayer: React.Dispatch<React.SetStateAction<IPlayer>>
  player: IPlayer
  data?: ISessionDetails
  renderProgress: (progress: number, duration: number) => void
}) => {
  const progressStart = annotation.start / 1000 / duration
  const progressEnd = annotation.end / 1000 / duration

  if (audio && audio.id) {
    const soundsElements = sound.find(audio?.id)
    const duration = soundsElements.duration

    if (audio) {
      play({
        name: audio.name,
        id: audio.id,
        startAt: progressStart * duration,
        endAt: progressEnd * duration,
        player,
        setPlayer,
        data,
        renderProgress,
      })
    }
  }
}

export const play = async ({
  name,
  id,
  startAt,
  endAt,
  isTranscription,
  transcriptionName,
  setPlayer,
  player,
  data,
  renderProgress,
}: {
  name: string
  id: string
  startAt?: number
  endAt?: number
  isTranscription?: boolean
  transcriptionName?: string
  setPlayer: React.Dispatch<React.SetStateAction<IPlayer>>
  player: IPlayer
  data?: ISessionDetails
  renderProgress: (progress: number, duration: number) => void
}) => {
  const time = isTranscription ? {} : { time: startAt ? startAt : 0 }
  let startPosition: number

  setPlayer({
    ...player,
    isPlaying: true,
    ...time,
    name,
    id,
  })
  sound.stopAll()

  const soundsElements = sound.find(id)
  const songInstance = await soundsElements?.play(
    isTranscription
      ? transcriptionName
      : {
          start: startAt ? startAt : 0,
          end: endAt ? endAt : 0,
          complete: (sound) => {
            console.log('complete')
          },
        },
  )

  songInstance?.on('end', () => {
    if (endAt != undefined && startAt != undefined && data?.duration) {
      const duration = data?.duration / 1000
      renderProgress(startPosition, duration)
      setPlayer({ ...player, isPlaying: false, name: '', id: '' })
    } else {
      renderProgress(0, 0)
      setPlayer({
        ...player,
        isPlaying: false,
        name: player.name,
        id: player.id,
      })
    }
  })

  songInstance?.on('progress', async (progress: number, duration: number) => {
    if (startPosition === undefined) {
      startPosition = progress
    }

    renderProgress(progress, duration)
  })
}
