// @ts-nocheck

import {
  Box,
  Flex,
  Spinner,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  Column,
  useAsyncDebounce,
  useMountedLayoutEffect,
  TableInstance,
} from 'react-table'
import { generateSortingIndicator } from './sort'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

export type IColumn = Column & {
  isAction?: boolean
}

interface ITableElement extends TableProps {
  tableInstance: TableInstance
  isLoading: boolean
  maxWidthOfTable?: string
  isSearching: boolean
  height: string
  table_info?: string | undefined
  onRemoveClick: (id: string) => void
  onClearSort: () => void
  onEditClick: (id: string) => void
  onChangeSort: (sortBy: Array<{ id: string; desc: boolean }>) => void
}

export const headerHeight = '46px'
export const marings = '30px'
export const marginTop = '25px'

export const TableElement = ({
  maxWidthOfTable,
  tableInstance,
  onChangeSort,
  onClearSort,
  isLoading,
  isSearching,
  onEditClick,
  onRemoveClick,
  height,
  table_info,
  ...props
}: ITableElement) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = tableInstance

  const onSort = useAsyncDebounce(() => {
    onChangeSort(sortBy)
  }, 0)

  useMountedLayoutEffect(() => {
    onSort()
  }, [sortBy])

  return (
    <Box
      overflow={rows.length === 0 || isLoading ? 'hidden' : 'overlay'}
      display="block"
      maxW={maxWidthOfTable ? maxWidthOfTable : '100%'}
      height={`calc(${height} - ${marginTop})`}
      maxHeight={`calc(${height} - ${marginTop})`}
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
          height: '4px',
        },
        '&::-webkit-scrollbar-track': {
          marginTop: headerHeight,
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'var(--chakra-colors-brand-500)',
          borderRadius: '24px',
        },
      }}
    >
      <Table {...props} {...getTableProps()}>
        <Thead position="sticky" top={0} zIndex="2">
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>
                  <Flex alignItems="center">
                    <Text mr="5px" data-qa__id="column_header">
                      {column.render('Header')}
                    </Text>
                    <div>{generateSortingIndicator(column, onClearSort)}</div>
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody
          {...getTableBodyProps()}
          position="relative"
          height={`calc(${height} - ${headerHeight} - ${marings})`}
          sx={{
            '.table_transition-appear': {
              opacity: 0.01,
            },
            '.table_transition-appear-done': {
              opacity: 1,
              transition: `opacity 0.2s ease-in`,
            },
          }}
        >
          {isLoading || isSearching ? (
            <Tr>
              <Td p={0} m={0}>
                <Box
                  w="100%"
                  top="50px"
                  position="absolute"
                  display="flex"
                  justifyContent="center"
                >
                  <Spinner size="xl" data-qa__id="table_loading_spinner" />
                </Box>
              </Td>
            </Tr>
          ) : rows.length > 0 ? (
            rows.map((row, index) => {
              let extraProps = {}

              prepareRow(row)

              if (row.values?.state?.val === 'Ready') {
                extraProps = {
                  _hover: {
                    bg: '#f6f9fcaa',
                  },
                }
              } else {
                extraProps = {
                  style: { ...row.getRowProps().style, opacity: 0.6 },
                  _hover: {
                    cursor: 'not-allowed',
                  },
                }
              }

              return (
                <TransitionGroup>
                  <CSSTransition
                    key={`row_transition${row.id}_${index}`}
                    in={true}
                    classNames="table_transition"
                    timeout={{ appear: 50 }}
                    appear
                  >
                    <Tr
                      {...row.getRowProps()}
                      {...extraProps}
                      data-qa__id="table_row"
                    >
                      {row.cells.map((cell) => {
                        return (
                          <Td
                            {...cell.getCellProps()}
                            justifyContent={cell?.value?.justify}
                            display="flex"
                            alignItems="center"
                          >
                            {cell.render('Cell')}
                          </Td>
                        )
                      })}
                    </Tr>
                  </CSSTransition>
                </TransitionGroup>
              )
            })
          ) : (
            <Tr>
              <Td p={0} m={0}>
                <Box
                  position="absolute"
                  w="100%"
                  top="50px"
                  display="flex"
                  justifyContent="center"
                  data-qa__id="table_no_results"
                >
                  <p>{table_info ? table_info : 'No results'}</p>
                </Box>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  )
}
