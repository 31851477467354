import { encode } from '@msgpack/msgpack'

const AUDIO_ENCODER_CONFIG = {
  codec: 'opus',
  sampleRate: 16_000,
  numberOfChannels: 1,
  bitrate: 16_000,
  opus: {
    application: 'voip',
    signal: 'voice',
    usedtx: true,
  },
}

class OpusEncoder {
  private encoder: any
  private writer: any
  private package_size: number
  private lastErrorHandler: any

  public constructor(writer: any, package_size: number, lastErrorHandler: any) {
    this.package_size = package_size
    this.writer = writer
    this.lastErrorHandler = lastErrorHandler

    this.encoder = new (window as any).AudioEncoder({
      output: (encoded: any, _: any) => {
        const buffer = new ArrayBuffer(encoded.byteLength)
        encoded.copyTo(buffer)

        const data: Uint8Array = encode({
          INPUT: new Int8Array(buffer),
          TIMESTAMP: new Date().getTime(),
        })

        let dataToSend = new Uint8Array(this.package_size)

        if (data.length < this.package_size) {
          dataToSend.set(data)

          this.writer.write(dataToSend).catch(() => {
            if (this.lastErrorHandler) {
              this.lastErrorHandler()
            }
          })
        }
      },
      error: (e: any) => {
        console.log(e)
      },
    })
    this.encoder.configure(AUDIO_ENCODER_CONFIG)
  }

  public encode(audio: Float32Array, sample_rate: number): void {
    const audioData = new (window as any).AudioData({
      format: 'f32',
      data: audio,
      sampleRate: sample_rate,
      numberOfChannels: 1,
      numberOfFrames: audio.length,
      timestamp: new Date().getTime(),
    })
    this.encoder.encode(audioData)
  }
}

class OpusEncoderCollection {
  private static instance: OpusEncoderCollection
  private _encoders: Map<string, OpusEncoder>

  private constructor() {
    this._encoders = new Map()
  }

  public static getInstance() {
    if (!OpusEncoderCollection.instance) {
      OpusEncoderCollection.instance = new OpusEncoderCollection()
    }
    return OpusEncoderCollection.instance
  }

  public getEncoder(
    session_id: string,
    voice_id: string,
    model_id: string,
    writer: any,
    package_size: number,
    lastErrorHandler: any,
  ): OpusEncoder {
    const key = `${session_id}|${voice_id}|${model_id}`

    if (!this._encoders.has(key)) {
      const encoder = new OpusEncoder(writer, package_size, lastErrorHandler)
      this._encoders.set(key, encoder)
    }

    return this._encoders.get(key)!
  }
}

export { OpusEncoder, OpusEncoderCollection }
