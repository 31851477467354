import React, { ReactNode, useEffect, useState } from 'react'
import {
  AppDispatch,
  useAppDispatch,
  useAppSelector,
} from '../../utils/reduxUtils'
import analyserFrequency from 'analyser-frequency-average'
import { IAudioDevice } from '../../components/DevicesController/devicesController'
import {
  infer,
  openInferenceServiceTransport,
  closeInferenceServiceTransport,
} from '../../utils/audioUtils'
import {
  setDeviceTest,
  setSelectedVoice,
  updateDeviceTest,
  updateMediaState,
} from '../../features/audio/actions'
import { CreateToastFnReturn, Flex, useToast } from '@chakra-ui/react'
import { v4 as uuidv4 } from 'uuid'
import { OpusDecoderWebWorker } from 'opus-decoder'
import {
  setLiveManagementSession,
  updateGetter,
} from '../../features/audioManagement/actions'
import { logout } from '../../features/authorization/actions'
import { newVoiceElements } from './live'
import { saveLiveSession } from '../../features/session/actions'
import { PreventChangePath } from '../../components/PreventChangePath/preventChangePath'
import { useCallbackPrompt } from '../../customHooks/useCallbackPrompt'
import { ModalSurvey } from '../../components/Survey/modalSurvey'
import { DeviceTestModal } from '../../components/DeviceTestModal/deviceTestModal'
import { setAsyncDelayFilter } from '../../utils/setAsyncDelayFilter'
import { IUser, IVoice } from '../../models/user/user'
import { IMediaState } from '../../models/audio/audio'

export enum EConversionState {
  draft = 'draft',
  processing = 'processing',
  completed = 'completed',
}

let messageBus = {
  send: (message: string, value: string) => {
    return [message, value]
  },
}

try {
  messageBus = window.require('electron').ipcRenderer
} catch (_) {}

export enum EConversionType {
  manual = 'manual',
  generated = 'generated',
}
export enum ERecordingState {
  draft = 'draft',
  processing = 'processing',
  completed = 'completed',
}

let dispatcherTimeout: ReturnType<typeof setTimeout>
const decoder = new OpusDecoderWebWorker({
  sampleRate: 16000,
} as any) // Upstream added sampleRate, but didn't update d.ts

enum ELiveConnectionState {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

const broadcastLiveConnectionState = (state: ELiveConnectionState) => {
  try {
    window.top?.postMessage(state, '*')
    messageBus.send('liveConnectionState', state)
  } catch {}
}

const buildStream = async (
  mediaState: IMediaState,
  custom_inference_url: string,
  toast: CreateToastFnReturn,
  dispatch: AppDispatch,
  inputDevice: IAudioDevice,
  outputDevice: IAudioDevice | null,
  inputAudioContext: AudioContext,
  sessionId: string,
  dataCenterId: string,
  user_data: IUser,
  modelLatency?: number,
  voiceId?: string,
  modelId?: string,
  voiceDisplayName?: string,
  onOpenSurvey?: (options: any) => void,
  deviceTest?: { isOpen: boolean },
  obs_sync?: boolean,
  onEnd?: () => void,
) => {
  let inputStreamNode: AudioWorkletNode

  await decoder.reset()
  await decoder.ready

  const inputStream = await navigator.mediaDevices
    .getUserMedia({
      audio: {
        deviceId: {
          exact: inputDevice.device_id || inputDevice.api_id,
        },
        sampleRate: {
          min: 16000,
        },
        //@ts-ignore
        latency: 0,
        channelCount: 1,
        autoGainControl: false,
        echoCancellation: false,
        noiseSuppression: false,
      },
    })
    .catch((e) => {
      onEnd?.()
      console.warn(e)
    })

  if (!inputStream) {
    broadcastLiveConnectionState(ELiveConnectionState.DISCONNECTED)
    dispatch(updateMediaState({ isConnecting: false }))
    onEnd?.()
    return
  }

  const source = inputAudioContext.createMediaStreamSource(inputStream)
  const destination = inputAudioContext.createMediaStreamDestination()
  let transportReader: any = null

  if (voiceId && modelId) {
    // TODO: Try https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/createBufferSource
    try {
      // Useful resources
      // https://developer.mozilla.org/en-US/docs/Web/API/AudioWorkletNode
      // https://developer.chrome.com/blog/audio-worklet/
      // https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_AudioWorklet
      await inputAudioContext.audioWorklet.addModule(
        '../worklet/processor_input.js',
      )
    } catch (e) {
      onEnd?.()
      console.warn(e)
    }

    try {
      inputStreamNode = new AudioWorkletNode(
        inputAudioContext,
        'processor_input',
      )
    } catch {
      onEnd?.()
      broadcastLiveConnectionState(ELiveConnectionState.DISCONNECTED)
      dispatch(updateMediaState({ isConnecting: false }))
      return
    }

    inputStreamNode.port.onmessage = (event) => {
      if (event.data) {
        infer(event.data, sessionId, voiceId, modelId, 16_000)
      }
    }

    source.connect(inputStreamNode)
    inputStreamNode.connect(inputAudioContext.destination)
    inputStreamNode.connect(destination)
    if (obs_sync) {
      setAsyncDelayFilter(modelLatency)
    }
  } else {
    broadcastLiveConnectionState(ELiveConnectionState.DISCONNECTED)
    dispatch(updateMediaState({ isConnecting: false }))
    source.connect(inputAudioContext.destination)
  }

  if ((inputAudioContext as any)?.state !== 'closed') {
    if (outputDevice && outputDevice.device_id !== null) {
      await (inputAudioContext as any).setSinkId(
        outputDevice.device_id === 'default' ? '' : outputDevice.device_id,
      )
    } else {
      await (inputAudioContext as any).setSinkId({ type: 'none' })
    }
  }

  if (voiceId && modelId) {
    const startTime = new Date().getTime()
    const { reader } = await openInferenceServiceTransport(
      mediaState,
      custom_inference_url,
      dataCenterId,
      sessionId,
      voiceId,
      modelId,
      user_data,
      (session_id) => {
        dispatch(
          saveLiveSession({
            toast,
            sessionMeta: deviceTest?.isOpen ? { is_test: true } : null,
            session: {
              name: `${
                deviceTest?.isOpen ? 'Test' : 'Live'
              }: ${voiceDisplayName} ${session_id.split('-')[0]}`,
              showroom_categories: [],
              state: ERecordingState.draft,
              recording_id: session_id,
            },
            conversion: {
              data: {
                target_sample_rate: 24_000,
                name: voiceDisplayName,
                voice: voiceId,
                type: EConversionType.generated,
                state: EConversionState.completed,
                conversion_model: modelId,
                conversion_voice: `${voiceId}/${modelId}`,
                description: '',
              },
            },
          }),
        )

        dispatch(
          updateMediaState({
            latency: 0,
            isConnected: true,
            isConnecting: false,
          }),
        )

        broadcastLiveConnectionState(ELiveConnectionState.CONNECTED)
      },
      () => {
        if (obs_sync) {
          setAsyncDelayFilter(0)
        }
        onEnd?.()
        // check if current time of recording is longer than 10 second and open survey
        if (onOpenSurvey && inputAudioContext?.currentTime > 10) {
          onOpenSurvey({ isOpen: true, sessionId })
        }

        transportReader = null

        dispatch(
          setSelectedVoice({
            voice: newVoiceElements(),
          }),
        )

        dispatch(
          updateMediaState({
            latency: 0,
            isConnected: false,
            isConnecting: false,
            isRecording: false,
          }),
        )

        broadcastLiveConnectionState(ELiveConnectionState.DISCONNECTED)
      },
      () => {
        if (obs_sync) {
          setAsyncDelayFilter(0)
        }
        if (!transportReader) {
          if (onOpenSurvey) {
            onOpenSurvey({ isOpen: true, sessionId })
          }

          const closeTime = new Date().getTime()

          if (closeTime - startTime <= 5000) {
            toast({
              title: `Failed to connect to inference server. Please make sure that your firewall is not blocking port 443`,
              position: 'bottom-left',
              status: 'warning',
              isClosable: true,
            })
          }

          dispatch(
            setLiveManagementSession({
              session_id: sessionId,
              avatar_display_name: '',
              avatar_id: '',
              is_started: false,
              is_recording: false,
              available_devices: [],
            }),
          )
        }

        clearTimeout(dispatcherTimeout)
        closeInferenceServiceTransport()

        if (inputAudioContext.state !== 'closed') {
          inputAudioContext?.close()
        }

        inputStreamNode?.disconnect()

        inputStream?.getTracks().forEach((track: any) => {
          track.stop()
        })

        dispatch(
          setSelectedVoice({
            voice: newVoiceElements(),
          }),
        )

        onEnd?.()
        dispatch(
          updateMediaState({
            latency: 0,
            isConnected: false,
            isConnecting: false,
            isRecording: false,
          }),
        )

        broadcastLiveConnectionState(ELiveConnectionState.DISCONNECTED)
        transportReader = null
      },
      voiceDisplayName,
    )

    transportReader = reader
  }

  const dispatcher = async () => {
    if (transportReader) {
      const t0 = performance.now()

      const { value, done } = await transportReader.read().catch(() => {
        return { value: null, done: true }
      })

      if (!value || done) {
        return
      }

      const latency = performance.now() - t0

      if (latency > 40) {
        dispatch(updateMediaState({ latency: latency }))
      }

      const splitValue = [...value]
        .join(',')
        .split([1, 2, 3, 255, 0, 255, 3, 2, 1].join(','))
        .map(
          (item) =>
            new Uint8Array(
              item
                .split(',')
                .filter((sub) => sub != '')
                .map((sub) => Number(sub)),
            ),
        )
        .filter((item) => item.length > 0)

      for (const frame of splitValue) {
        const { channelData, errors } = await decoder.decodeFrame(frame)

        if (!errors.length) {
          inputStreamNode.port.postMessage(channelData[0])
        } else {
          console.warn(errors)
        }
      }

      dispatcherTimeout = setTimeout(() => {
        dispatcher()
      }, 0)
    }
  }

  dispatcher()

  return {
    inputStream: inputStream,
  }
}

const LiveSession = ({
  children,
  carousel,
  canShowDeviceTest,
}: {
  children: ReactNode
  carousel: ReactNode
  canShowDeviceTest: boolean
}) => {
  const {
    deviceTest,
    selectedDevices,
    selectedVoice,
    areAudioPermissions,
    mediaState,
    availableDevices,
  } = useAppSelector((state) => state.audio)
  const {
    data_center,
    custom_inference_url,
    features,
    should_show_device_test,
    is_first_login,
    meaning_user_id,
    s3_models,
  } = useAppSelector((state) => state.user)

  const obs_sync = !!features.find((feature) => feature === 'obs_sync')
  const { pauseGetter, liveSession: liveSessionData } = useAppSelector(
    (state) => state.audioManagement,
  )

  const dispatch = useAppDispatch()
  const [sessionId, setSessionId] = useState('')
  const [media, setMedia] = useState<any>(null)
  const toast = useToast()

  const [isOpenSurvey, setIsOpenSurvey] = useState<{
    isOpen: boolean
    sessionId: string | null
  }>({ isOpen: false, sessionId: null })

  const onCloseSurvey = () => {
    setIsOpenSurvey({ isOpen: false, sessionId: null })
  }
  const onOpenSurvey = (sessionId: {
    isOpen: boolean
    sessionId: string | null
  }) => {
    setIsOpenSurvey(sessionId)
  }

  const setEmptyLiveSession = () => {
    dispatch(
      setLiveManagementSession({
        session_id: '',
        avatar_display_name: '',
        avatar_id: '',
        is_recording: false,
        is_started: false,
        available_devices: {},
      }),
    )
  }

  useEffect(() => {
    const handleMessage = (
      event: MessageEvent<{
        action: string
        avatar_id?: string
        record?: boolean
      }>,
    ) => {
      console.log('Handling postMessage event', event)

      if (event.data.action === 'start_session' && event.data.avatar_id != '') {
        const voices = s3_models.map((model) => model.voices).flat()
        const selectedVoice = voices.find(
          ({ avatar_identifier }) => avatar_identifier === event.data.avatar_id,
        )

        if (selectedVoice) {
          dispatch(
            updateMediaState({
              canBeRecorded: true,
              shouldBeRecorded: event.data.record,
            }),
          )
          dispatch(
            setSelectedVoice({
              voice: selectedVoice,
            }),
          )
        }

        return
      }

      if (event.data.action === 'stop_session' && event.data.avatar_id != '') {
        dispatch(
          setSelectedVoice({
            voice: newVoiceElements(),
          }),
        )

        return
      }

      if (event.data.action === 'logout') {
        dispatch(logout({ url: logout_url }))

        return
      }
    }
    window.addEventListener('message', handleMessage, false)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  })

  useEffect(() => {
    if (
      areAudioPermissions &&
      availableDevices.input_devices !== null &&
      !pauseGetter
    ) {
      if (!mediaState.isConnected && !mediaState.isConnecting) {
        dispatch(updateGetter(true))
      }
    }
  }, [areAudioPermissions, availableDevices])

  useEffect(() => {
    return () => {
      setEmptyLiveSession()
      dispatch(updateGetter(false))
    }
  }, [])

  const [isConnected, setIsConnected] = useState<any>(null)

  useEffect(() => {
    if (
      media &&
      mediaState !== media &&
      mediaState.isConnected !== isConnected
    ) {
      setIsConnected(mediaState.isConnected)
      let session_id = sessionId
      if (!sessionId) {
        session_id = uuidv4()
        setSessionId(session_id)
      }

      dispatch(
        setLiveManagementSession({
          session_id: session_id,
          avatar_display_name: selectedVoice.voice.display_name,
          avatar_id: selectedVoice.voice?.avatar_identifier,
          is_started: mediaState.isConnected,
          is_recording: mediaState.shouldBeRecorded,
          available_devices: availableDevices,
        }),
      )
      setMedia(mediaState)
    }
    if (!media) {
      setMedia(mediaState)
    }
  }, [mediaState])

  const bindMediaPipe = () => {
    const inputAudioContext = new AudioContext({
      sampleRate: 16_000,
    })

    if (!selectedDevices.input_device) {
      dispatch(updateMediaState({ isConnecting: false }))
      return
    }

    const inputStreamPromise = buildStream(
      mediaState,
      custom_inference_url,
      toast,
      dispatch,
      selectedDevices.input_device,
      selectedDevices.output_device,
      inputAudioContext,
      sessionId,
      selectedVoice.voice.data_center_id,
      user_data,
      selectedVoice.voice.latency,
      selectedVoice.voice.key,
      selectedVoice.voice.model_id,
      selectedVoice.voice.display_name,
      deviceTest.isOpen ? () => {} : onOpenSurvey,
      deviceTest,
      obs_sync,
      () => {
        setSessionId(uuidv4())
      },
    )

    return () => {
      clearTimeout(dispatcherTimeout)
      closeInferenceServiceTransport()

      if (inputAudioContext.state !== 'closed') {
        inputAudioContext?.close()
      }

      inputStreamPromise?.then((properties) => {
        properties?.inputStream?.getTracks().forEach((track) => {
          track.stop()
        })
      })
    }
  }

  const [liveSessionId, setLiveSessionId] = useState<null | string>(null)

  useEffect(() => {
    if (
      areAudioPermissions &&
      !mediaState.isConnected &&
      !mediaState.isConnecting
    ) {
      dispatch(
        updateMediaState({
          latency: 0,
          isConnecting: selectedVoice.voice.id !== '',
        }),
      )

      return bindMediaPipe()
    }
  }, [sessionId, areAudioPermissions, liveSessionId, selectedDevices])

  useEffect(() => {
    if (areAudioPermissions && selectedVoice.voice.avatar_identifier) {
      setLiveSessionId(liveSessionData.session_id)
    } else {
      setLiveSessionId(null)
    }
  }, [selectedVoice])

  const is_controlled = !!features.find(
    (feature) =>
      feature === 'is_controlled_by_live_session_api' ||
      feature === 'is_controlled_by_external_site',
  )
  const refreshCondition = is_controlled && isConnected
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(refreshCondition)
  const user_data = useAppSelector((state) => state.user)

  const { potential_issues, can_show_quality_survey, logout_url } = user_data

  useEffect(() => {
    if (should_show_device_test || is_first_login) {
      dispatch(updateDeviceTest({ isOpen: true }))
    }
  }, [])

  return (
    <>
      {!is_controlled && deviceTest.isOpen && canShowDeviceTest && (
        <DeviceTestModal
          isFirstLogin={is_first_login}
          carousel={carousel}
          deviceTest={deviceTest}
          onClose={(withoutSavingChanges) => {
            dispatch(updateDeviceTest({ isOpen: false }))
            if (!withoutSavingChanges) {
              dispatch(setDeviceTest())
            }
          }}
        />
      )}
      {!is_controlled &&
        can_show_quality_survey &&
        !deviceTest.isOpen &&
        potential_issues.length !== 0 && (
          <ModalSurvey isOpenSurvey={isOpenSurvey} onClose={onCloseSurvey} />
        )}
      <Flex w="100%" gap="5">
        <PreventChangePath
          refreshCondition={refreshCondition}
          isOpen={showPrompt}
          onConfirm={confirmNavigation}
          onCancel={cancelNavigation}
        />
      </Flex>
      <>{children}</>
    </>
  )
}

export default LiveSession
