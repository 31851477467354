import { createAction } from '@reduxjs/toolkit'
import {
  IModelParameters,
  ISelectedParameters,
  IUserData,
} from '../../models/user/user'
import { CreateToastFnReturn } from '@chakra-ui/react'
import { NavigateFunction } from 'react-router-dom'

export const setUserData = createAction<IUserData>('user/SET_USER_DATA')
export const setIsAcceptingEULA = createAction<boolean>(
  'user/SET_IS_ACCEPTING_EULA',
)
export const setEULAAccept = createAction<boolean>('user/SET_EULA_ACCEPT')
export const setSelectedParameters = createAction<ISelectedParameters[]>(
  'user/SET_SELECTED_PARAMETERS',
)

export const setUserVoiceParameter = createAction<{
  body: {
    model_id: string
    avatar_identifier: string
    key: string
  }
  parameters: IModelParameters[]
  toast: CreateToastFnReturn
}>('user/SET_USER_VOICE_PARAMETER')

export const acceptEULA = createAction<{
  toast: CreateToastFnReturn
  navigate: NavigateFunction
}>('user/ACCEPT_EULA')
