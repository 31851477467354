import { createAction } from '@reduxjs/toolkit'
import {
  IMetrics,
  ISessionRequest,
  ISessionsResponse,
} from '../../models/sessions/sessions'

const actionPrefix = 'sessions'
const getActionName = (action: string) => {
  return `${actionPrefix}/${action}`
}

export const querySessions = createAction<{
  url?: string | null
  body: ISessionRequest
}>(getActionName('sessions/QUERY_SESSIONS'))

export const hydrateSessions = createAction<ISessionsResponse>(
  getActionName('sessions/HYDRATE_SESSIONS'),
)
export const hydrateMetrics = createAction<IMetrics>(
  getActionName('sessions/HYDRATE_METRICS'),
)

export const setIsLoadingSessions = createAction<boolean>(
  getActionName('sessions/IS_LOADING'),
)
export const setIsLoadingMetrics = createAction<boolean>(
  getActionName('sessions/SET_LOADING_METRICS'),
)
export const setUsedQuery = createAction<any>(
  getActionName('sessions/SET_USED_QUERY'),
)
