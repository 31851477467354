import React, { useMemo } from 'react'
import {
  Box,
  CreateToastFnReturn,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useToast,
} from '@chakra-ui/react'
import { IModelParameters, iS3Model, IVoice } from '../../models/user/user'
import { useAppDispatch, useAppSelector } from '../../utils/reduxUtils'
import { useAsyncDebounce } from 'react-table'
import { setUserVoiceParameter } from '../../features/user/actions'
const LiveSlider = ({
  model_parameters,
  voice,
}: {
  model_parameters: iS3Model
  voice: IVoice
}) => {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { selected_parameters } = useAppSelector((state) => state.user)

  const userSelectedParameters = selected_parameters.find(
    ({ avatar_identifier }) => avatar_identifier === voice.avatar_identifier,
  )

  const { mediaState } = useAppSelector((state) => state.audio)
  const onUpdateParameter = useAsyncDebounce(
    (value: {
      toast: CreateToastFnReturn
      parameters: IModelParameters[]
      body: {
        model_id: string
        avatar_identifier: string
        key: string
      }
    }) => {
      if (!mediaState.isConnected && !mediaState.isConnecting) {
        dispatch(setUserVoiceParameter(value))
      }
    },
    150,
  )

  const slider = useMemo(() => {
    return userSelectedParameters ? (
      <Box
        w="100%"
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        <Flex
          mt="2"
          fontSize="xs"
          fontWeight="semibold"
          justifyContent="flex-start"
          color={mediaState.isConnected ? 'white' : 'auto'}
        >
          Voice Intensity
        </Flex>
        <Slider
          isDisabled={mediaState.isConnected || mediaState.isConnecting}
          min={0}
          max={model_parameters.parameters.length - 1}
          step={1}
          aria-label="slider-ex-6"
          onChange={(val) => {
            const avatar_identifier = model_parameters.voices.find(
              ({ voice_id }) => voice_id === voice.voice_id,
            )

            if (avatar_identifier?.avatar_identifier) {
              onUpdateParameter({
                toast,
                parameters: model_parameters.parameters,
                body: {
                  model_id: model_parameters.model_id,
                  avatar_identifier: avatar_identifier.avatar_identifier,
                  key: model_parameters.parameters[val].key,
                },
              })
            }
          }}
          value={model_parameters.parameters.findIndex(
            ({ temperature }) =>
              userSelectedParameters.temperature === temperature,
          )}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>

        <Flex
          color={mediaState.isConnected ? 'white' : 'auto'}
          fontSize="xs"
          fontWeight="semibold"
          justifyContent="center"
        >
          {userSelectedParameters.key}
        </Flex>
      </Box>
    ) : (
      <></>
    )
  }, [userSelectedParameters?.temperature, mediaState])

  return slider
}

export default LiveSlider
