/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import { combineEpics } from 'redux-observable'
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators'
import { concat, EMPTY, of } from 'rxjs'
import { AppEpic } from '../../utils/reduxUtils'
import {
  checkIfLogged,
  checkIsStatus401,
  iframeLogin,
  isLoading,
  login,
  logout,
  setError,
  setIsLogged,
} from './actions'
import { setUserData } from '../user/actions'
import { authorizationErrStatus } from '../../utils/errorStatuses'
import { privatePagesDesktop, publicPages } from '../../AppWrapper'
export const Logout: AppEpic<ReturnType<typeof logout>> = (
  action$,
  state$,
  { authorization },
) =>
  action$.pipe(
    filter(logout.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      return concat(
        of(isLoading(true)),
        authorization.logoutSSO(action.payload.url).pipe(
          catchError(() => {
            return concat(of())
          }),
        ),
        authorization.logout().pipe(
          switchMap(() => {
            try {
              window.top?.postMessage('LOGOUT', '*')
            } catch {}
            window.location.pathname = '/login'
            return concat(
              of(isLoading(false)),
              of(setIsLogged({ isLogged: false })),
            )
          }),
          catchError(() => {
            return concat(of(isLoading(false)))
          }),
        ),
      )
    }),
  )

export const assignUserSelectedParameters = (response: any) => {
  const selectedParameters: any = []

  response.s3_models.forEach((model: any) => {
    if (model.parameters && model.parameters.length > 0) {
      let defaultParameter = model.parameters.find(
        (element: any) => element.is_default || element.is_default === 'true',
      )
      defaultParameter = defaultParameter
        ? defaultParameter
        : model.parameters[0]
      model.voices.forEach(({ avatar_identifier }: any) => {
        const userParameter = response.selected_parameters.find(
          (element: any) => element.avatar_identifier === avatar_identifier,
        )
        if (userParameter) {
          let parameter = model.parameters.find(
            (element: any) => userParameter.key === element.key,
          )
          selectedParameters.push({
            ...userParameter,
            ...parameter,
          })
        } else {
          selectedParameters.push({
            avatar_identifier,
            model_id: model.model_id,
            key: defaultParameter.key,
            ...defaultParameter,
          })
        }
      })
    }
  })

  const can_change_parameter =
    response.features.filter(
      (element: string) => element === 'can_change_parameter',
    ).length > 0

  response.can_change_parameter = can_change_parameter
  response.selected_parameters = selectedParameters

  return response
}

export const Login: AppEpic<ReturnType<typeof login>> = (
  action$,
  state$,
  { authorization },
) =>
  action$.pipe(
    filter(login.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      return concat(
        of(isLoading(true)),
        of(setError({ error: {} })),
        authorization.login(action.payload).pipe(
          switchMap((AjaxResponse) => {
            if (action.payload.isSSO) {
              let loginUrl =
                AjaxResponse.response?.login_url ||
                `${process.env.REACT_APP_API_URL}/authorization/login?sso=${action.payload.tokenOrEmailOrDomain}`
              if (state.authorization.isForceAuth) {
                loginUrl += '&force_auth'
              }
              window.open(loginUrl, '_self')
              return concat(of(isLoading(true)))
            }
            const is_logged = localStorage.getItem('is_logged')
            const is_controlled =
              AjaxResponse.response?.features?.filter(
                (feature: string) =>
                  feature === 'is_controlled_by_live_session_api' ||
                  feature === 'is_controlled_by_external_site',
              ).length > 0
            if (
              is_controlled &&
              (is_logged === null || is_logged === 'false')
            ) {
              localStorage.removeItem('next')
              window.location.replace('/live')
            } else if (
              AjaxResponse.response.is_first_login &&
              !window.location.pathname.includes('live')
            ) {
              localStorage.removeItem('next')
              window.location.replace('/live')
            } else {
              const path = localStorage.getItem('next')
              localStorage.removeItem('next')
              if (path && window.location.pathname !== path) {
                window.location.replace(path)
              }
            }

            try {
              window.top?.postMessage('LOGIN', '*')
            } catch {}
            localStorage.setItem('is_logged', 'true')
            return concat(
              of(
                setUserData(
                  assignUserSelectedParameters(AjaxResponse.response),
                ),
              ),
              of(isLoading(false)),
              of(setIsLogged({ isLogged: true })),
            )
          }),
          catchError((err) => {
            localStorage.setItem('is_logged', 'false')
            if (action.payload.isSSO) {
              if (err.status === 404 || err.status === 400) {
                return concat(
                  of(isLoading(false)),

                  of(
                    setError({
                      error: {
                        token: err.response.detail,
                      },
                    }),
                  ),
                )
              } else {
                return concat(
                  of(isLoading(false)),
                  of(
                    setError({
                      error: {
                        token: authorizationErrStatus.rest,
                      },
                    }),
                  ),
                )
              }
            }
            if (window?.location.pathname) {
              const location = privatePagesDesktop.filter(({ path }) => {
                const charPlace = path.indexOf(':')
                const text = path.substring(
                  0,
                  charPlace != -1 ? charPlace : path.length,
                )
                return path !== '/' && window?.location.pathname.includes(text)
              })
              if (location.length > 0) {
                localStorage.setItem(
                  'next',
                  window?.location.pathname + window?.location.search,
                )
              }
            }
            const paths = publicPages.filter(
              ({ path }) => path === window.location.pathname,
            )
            if (window?.location.pathname !== '/login') {
              if (paths.length === 0) {
                window.location.replace('/login' + window.location.search)
              }
            }

            const errorText = authorizationErrStatus[err.status]
              ? authorizationErrStatus[err.status]
              : authorizationErrStatus.rest
            return concat(
              of(isLoading(false)),
              of(
                setError({
                  error: action.payload.tokenOrEmailOrDomain
                    ? {
                        token: errorText,
                      }
                    : {},
                }),
              ),
            )
          }),
        ),
      )
    }),
  )

export const IframeLogin: AppEpic<ReturnType<typeof iframeLogin>> = (
  action$,
  state$,
  { authorization },
) =>
  action$.pipe(
    filter(iframeLogin.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      return concat(
        of(isLoading(true)),
        of(setError({ error: {} })),
        authorization.iframe_login(action.payload).pipe(
          switchMap((AjaxResponse) => {
            let response = AjaxResponse.response
            if (action.payload.features.can_control_recording === 'true') {
              response.features.push('can_control_recording')
            } else if (
              action.payload.features.can_control_recording === 'false'
            ) {
              response.features = response.features.filter(
                (element: string) => element !== 'can_control_recording',
              )
            }

            if (action.payload.features.can_be_recorded === 'true') {
              response.features.push('can_be_recorded')
            } else if (action.payload.features.can_be_recorded === 'false') {
              response.features = response.features.filter(
                (element: string) => element !== 'can_be_recorded',
              )
            }
            if (action.payload.features.should_be_recorded === 'true') {
              response.features.push('should_be_recorded')
            } else if (action.payload.features.should_be_recorded === 'false') {
              response.features = response.features.filter(
                (element: string) => element !== 'should_be_recorded',
              )
            }
            const uniqueFeatures = Array.from(new Set(response.features))
            response.features = uniqueFeatures
            return concat(
              of(setUserData(assignUserSelectedParameters(response))),
              of(isLoading(false)),
              of(setIsLogged({ isLogged: true })),
            )
          }),
          catchError((err) => {
            const errorText = authorizationErrStatus[err.status]
              ? authorizationErrStatus[err.status]
              : authorizationErrStatus.rest
            return concat(
              of(isLoading(false)),
              of(
                setError({
                  error: {
                    token: errorText,
                  },
                }),
              ),
            )
          }),
        ),
      )
    }),
  )

export const CheckIfLoggedEpic: AppEpic<ReturnType<typeof checkIfLogged>> = (
  action$,
  state$,
  { authorization },
) =>
  action$.pipe(
    filter(checkIfLogged.match),
    withLatestFrom(state$),
    switchMap(() => {
      return concat(
        of(login({ tokenOrEmailOrDomain: undefined, isSSO: false })),
      )
    }),
  )

export const CheckIsStatus401: AppEpic<ReturnType<typeof checkIsStatus401>> = (
  action$,
  state$,
  { authorization },
) =>
  action$.pipe(
    filter(checkIsStatus401.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      if (action.payload === 401) {
        localStorage.removeItem('next')
        window.location.replace('/login' + window.location.search)
        return concat(of(setIsLogged({ isLogged: false })))
      } else {
        return EMPTY
      }
    }),
  )

export const authEpics = combineEpics(
  Login,
  CheckIfLoggedEpic,
  Logout,
  CheckIsStatus401,
  IframeLogin,
)
