import React, { PropsWithChildren, useState } from 'react'
import { Props as DayzedHookProps } from 'dayzed'
import { Month_Names_Short, Weekday_Names_Short } from './utils/calanderUtils'
import { Box, Button, Flex, Input } from '@chakra-ui/react'
import { CalendarPanel } from './components/calendarPanel'
import {
  CalendarConfigs,
  DatepickerConfigs,
  DatepickerProps,
  OnDateSelected,
  PropsConfigs,
} from './utils/commonTypes'
import {
  endOfDay,
  endOfWeek,
  format,
  previousFriday,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
} from 'date-fns'
import { mode, transparentize } from '@chakra-ui/theme-tools'
import { equals } from 'ramda'

interface RangeCalendarPanelProps {
  dayzedHookProps: DayzedHookProps
  configs: CalendarConfigs
  propsConfigs?: PropsConfigs
  selected?: Date | Date[]
}

const RangeCalendarPanel: React.FC<RangeCalendarPanelProps> = ({
  dayzedHookProps,
  configs,
  propsConfigs,
  selected,
}) => {
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null)

  // Calendar level
  const onMouseLeave = () => {
    setHoveredDate(null)
  }

  // Date level
  const onMouseEnterHighlight = (date: Date) => {
    if (!Array.isArray(selected) || !selected?.length) {
      return
    }
    setHoveredDate(date)
  }

  const isInRange = (date: Date) => {
    if (!Array.isArray(selected) || !selected?.length) {
      return false
    }
    let firstSelected = selected[0]
    if (selected.length === 2) {
      let secondSelected = selected[1]
      return firstSelected < date && secondSelected > date
    } else {
      return (
        hoveredDate &&
        ((firstSelected < date && hoveredDate >= date) ||
          (date < firstSelected && date >= hoveredDate))
      )
    }
  }

  return (
    <Flex onMouseLeave={onMouseLeave}>
      <CalendarPanel
        dayzedHookProps={dayzedHookProps}
        configs={configs}
        propsConfigs={propsConfigs}
        isInRange={isInRange}
        onMouseEnterHighlight={onMouseEnterHighlight}
      />
    </Flex>
  )
}

export interface RangeDatepickerProps extends DatepickerProps {
  selectedDates?: Date[]
  configs?: DatepickerConfigs
  disabled?: boolean
  defaultIsOpen?: boolean
  onDateChange: (date: Date[]) => void
  id?: string
  name?: string
  usePortal?: boolean
  children?: any
  isAddedConvenientFilters?: boolean
}

const DefaultConfigs: CalendarConfigs = {
  dateFormat: 'MM/dd/yyyy',
  monthNames: Month_Names_Short,
  dayNames: Weekday_Names_Short,
  firstDayOfWeek: 0,
}

export const RangeDatepicker: React.FC<RangeDatepickerProps> = ({
  configs,
  propsConfigs = {},
  id,
  name,
  usePortal,
  defaultIsOpen = false,
  isAddedConvenientFilters = false,
  children,
  ...props
}) => {
  const { selectedDates, minDate, maxDate, onDateChange, disabled } = props

  // chakra popover utils
  const [dateInView, setDateInView] = useState(selectedDates?.[0] || new Date())
  const [offset, setOffset] = useState(0)

  const calendarConfigs: CalendarConfigs = {
    ...DefaultConfigs,
    ...configs,
  }

  const handleOnDateSelected: OnDateSelected = ({ selectable, date }) => {
    if (!selectable) {
      return
    }
    let newDates = [...(selectedDates || [])]
    if (selectedDates?.length) {
      if (selectedDates?.length === 1) {
        let firstTime = selectedDates?.[0]
        if (firstTime < date) {
          newDates.push(date)
        } else {
          newDates.unshift(date)
        }
        onDateChange(calculateFromStartToEndDate(newDates))
      } else if (newDates.length === 2) {
        onDateChange([date])
      }
    } else {
      newDates.push(date)
      onDateChange(newDates)
    }
  }
  const calculateFromStartToEndDate = (dateArray: Date[]) => [
    startOfDay(dateArray[0]),
    endOfDay(dateArray[1]),
  ]

  const convenientFilters = [
    {
      name: 'Today',
      condition: calculateFromStartToEndDate([new Date(), new Date()]),
    },
    {
      name: 'Yesterday',
      condition: calculateFromStartToEndDate([
        subDays(new Date(), 1),
        subDays(new Date(), 1),
      ]),
    },
    {
      name: 'This week',
      condition: calculateFromStartToEndDate([
        startOfWeek(new Date()),
        endOfDay(new Date()),
      ]),
    },
    {
      name: 'Last week',
      condition: calculateFromStartToEndDate([
        startOfWeek(previousFriday(new Date())),
        endOfWeek(previousFriday(new Date())),
      ]),
    },
    {
      name: 'This month',
      condition: calculateFromStartToEndDate([
        startOfMonth(new Date()),
        endOfDay(new Date()),
      ]),
    },
    {
      name: 'Last 30 days',
      condition: calculateFromStartToEndDate([
        subDays(new Date(), 30),
        endOfDay(new Date()),
      ]),
    },
  ]

  const checkCondition = (condition: any) => {
    if (
      selectedDates?.length === 2 &&
      !!selectedDates?.[0] !== null &&
      !!selectedDates?.[1]
    ) {
      const datesFromStartOfDay = calculateFromStartToEndDate(selectedDates)
      if (equals(condition, datesFromStartOfDay)) {
        return 'primary'
      } else {
        return 'outline'
      }
    } else {
      return 'outline'
    }
  }

  return (
    <Box
      p="20px"
      gap="10px"
      borderWidth="1px"
      rounded="md"
      shadow="lg"
      data-qa__id="datepicker_body"
    >
      <Flex mb="20px" gap="10px">
        <Input
          disabled
          _disabled={
            !selectedDates?.[0]
              ? {
                  opacity: 1,
                  borderColor: `${mode('brand.500', 'brand.200')(props)}`,
                  boxShadow: `0px 0px 0px 1px ${transparentize(
                    `brand.500`,
                    1.0,
                  )}`,
                  borderWidth: '2px',
                }
              : {
                  opacity: 1,
                  borderWidth: 0,
                }
          }
          cursor="not-allowed"
          background="gray.100"
          color={!selectedDates?.[0] ? 'brand.500' : ''}
          _placeholder={{ color: !selectedDates?.[0] ? 'brand.500' : '' }}
          placeholder="From date"
          value={
            selectedDates?.[0] ? format(selectedDates?.[0], 'MM/dd/yyyy') : ''
          }
          data-qa__id="datepicker_from_date"
        />
        <Input
          background="gray.100"
          cursor="not-allowed"
          disabled
          _disabled={
            selectedDates?.[0] && !selectedDates?.[1]
              ? {
                  opacity: 1,
                  borderColor: `${mode('brand.500', 'brand.200')(props)}`,
                  boxShadow: `0px 0px 0px 1px ${transparentize(
                    `brand.500`,
                    1.0,
                  )}`,
                  borderWidth: '2px',
                }
              : {
                  borderWidth: 0,
                  opacity: 1,
                }
          }
          _placeholder={{
            color: selectedDates?.[0] && !selectedDates?.[1] ? 'brand.500' : '',
          }}
          value={
            selectedDates?.[1] ? format(selectedDates?.[1], 'MM/dd/yyyy') : ''
          }
          placeholder="To date"
          data-qa__id="datepicker_to_date"
        />
      </Flex>
      <RangeCalendarPanel
        dayzedHookProps={{
          onDateSelected: handleOnDateSelected,
          selected: selectedDates,
          monthsToDisplay: 2,
          date: dateInView,
          minDate: minDate,
          maxDate: maxDate,
          offset: offset,
          onOffsetChanged: setOffset,
          firstDayOfWeek: calendarConfigs.firstDayOfWeek,
        }}
        configs={calendarConfigs}
        propsConfigs={propsConfigs}
        selected={selectedDates}
      />
      {isAddedConvenientFilters && (
        <Flex
          flexWrap="wrap"
          gap="4px"
          p="0 0.75rem"
          mt="0.75rem"
          justifyContent="space-between"
        >
          {convenientFilters.map(({ name, condition }) => {
            const conditionEl = checkCondition(condition)
            return (
              <Button
                variant={conditionEl}
                key={`ConvenientFilters_${name}`}
                fontSize={14}
                width="100px"
                onClick={() => onDateChange(condition)}
                data-qa__id="datepicker_convenient_filter"
              >
                {name}
              </Button>
            )
          })}
        </Flex>
      )}
      {children}
    </Box>
  )
}
